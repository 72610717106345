import ContentOne from "../components/content/ContentOne";
import CTAThree from "../components/cta/CTAThree";
import CTATwo from "../components/cta/CTATwo";
import FAQThree from "../components/faq/FAQThree";
import FooterOne from "../components/footer/FooterOne";
import { Header } from "../components/header/Header";
import TitleFive from "../components/section-title/TitleFive";
import TitleFour from "../components/section-title/TitleFour";
import TitleThree from "../components/section-title/TitleThree";
import TitleTwo from "../components/section-title/TitleTwo";

const faqs = [
  {
    question: "What is block-by-block real estate investing?",
    answer:
      "Block-by-block real estate investing allows individuals to invest in specific portions or “blocks” of real estate projects. Instead of purchasing an entire property, investors can own a share or block, which gives them access to potential returns from rental income or property appreciation, without the need for full ownership.",
  },
  {
    question: "How does block-by-block investing work?",
    answer:
      "Investors can choose from available real estate projects listed on our platform, each divided into blocks. By purchasing one or more blocks, you become a fractional owner of the property. Returns are generated based on rental income, appreciation, or resale of the property. The platform manages all property operations, and investors receive regular updates and earnings.",
  },
  {
    question: "What are the risks involved with block-by-block investing?",
    answer:
      "Block by Block investing carries no risk, thanks to the backing of REITs that provide a solid layer of security for all investments. This structure ensures that your capital is protected and that returns on investment are guaranteed.",
  },
  {
    question: "Who can invest in block-by-block real estate?",
    answer:
      "Anyone over the age of 18 with sufficient financial resources can invest. However, because of the risks involved, block-by-block real estate investing is best suited for investors with a moderate-to-high risk tolerance and some experience in real estate or similar investments.",
  },
  {
    question: "How do I earn returns on my investment?",
    answer:
      "Returns on your block investment can come from two main sources: rental income from the property and potential appreciation of the property’s value. Investors typically receive returns in the form of quarterly distributions, and upon property sale, you may earn from the appreciation if the property has increased in value.",
  },
  {
    question: "Is my investment secure?",
    answer:
      "Investment are 100% secured With the expertise of a REITs agency, your investments are not only secure but also resilient against any form of risk, ensuring peace of mind and long-term stability.",
  },
];

const RealEstate = () => {
  return (
    <>
      <div style={{ backgroundColor: "#0c0f19" }}>
        <Header />
        <TitleThree />
      </div>
      {/* <TitleFive /> */}
      {/* <TitleFour /> */}
      {/* <TitleTwo /> */}
      <div className="py-24">
        <ContentOne />
      </div>
      <div style={{ backgroundColor: "#0c0f19" }}>
        {/* <CTATwo /> */}
        {/* <CTAThree /> */}
        <FAQThree faqs={faqs} />
      </div>
      <FooterOne />
    </>
  );
};

export default RealEstate;
